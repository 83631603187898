import { initSalesforceLiveAgent, onLoadSalesforceScript } from '@infrastructure/SalesforceLiveAgent'

import { addStyle, createButton, getButtonInnerText, getIcon, handleOnlineClick } from './utils'

type Options = {
    domainName: string
    organizationId: string
    deploymentId: string
    buttonId: string
    apiURL: string
    accessToken: string
}

export const onInitialClientRender = async (_: undefined, options: Options) => {
    const { domainName, buttonId, deploymentId, organizationId, apiURL, accessToken } = options

    if (!domainName || !deploymentId || !organizationId || !buttonId) return

    const translations = process.env.GATSBY_API_DATA ? JSON.parse(process.env.GATSBY_API_DATA) : []

    addStyle(translations)

    const ONLINE_BUTTON_ID = `liveagent_button_online_${buttonId}`
    const OFFLINE_BUTTON_ID = `liveagent_button_offline_${buttonId}`

    initSalesforceLiveAgent({
        domainName,
        onLoad: onLoadSalesforceScript({
            domainName,
            deploymentId,
            organizationId,
            buttonId: buttonId,
            onlineButtonId: ONLINE_BUTTON_ID,
            offlineButtonId: OFFLINE_BUTTON_ID,
        }),
    })

    const onlineButtonInnerText = getButtonInnerText('chatWithUs', translations)
    const offlineButtonInnerText = getButtonInnerText('weAreCurrentlyOffline', translations)

    const onlineButton = createButton(ONLINE_BUTTON_ID, onlineButtonInnerText, getIcon('#FFFFFF'))
    const offlineButton = createButton(OFFLINE_BUTTON_ID, offlineButtonInnerText, getIcon('#627495'), true)

    // Append the buttons to the body
    document.body.appendChild(onlineButton)
    document.body.appendChild(offlineButton)

    onlineButton.addEventListener('click', handleOnlineClick(buttonId))

    return () => {
        onlineButton.removeEventListener('click', handleOnlineClick(buttonId))
        document.body.removeChild(onlineButton)
        document.body.removeChild(offlineButton)
    }
}
