import Cookies from 'js-cookie'

import type { Opt } from '@application/Types/Opt'
import {
    COOKIE_DOMAIN,
    GDPR_COOKIES_EXPIRES_IN_DAYS,
    GDPR_MANDATORY_COOKIE_NAME,
    GDPR_MARKETING_COOKIE_NAME,
    GDPR_PERFORMANCE_COOKIE_NAME,
    LANGUAGE_COOKIE_NAME,
    OLD_GDPR_COOKIE_NAME,
    VISITOR_UUID_COOKIE_NAME,
    VISITOR_UUID_COOKIES_EXPIRES_IN_DAYS,
} from '@core/Constants/CookieConstants'
import { CookiesState, CookiesType } from '@icontainers/design-system/dist/molecules/AcceptCookiesModal/types'
import { showCookiesPopup } from '@icontainers/design-system/dist/utils'
import { ClarityType } from '@infrastructure/Clarity'
import { MixpanelType } from '@infrastructure/Mixpanel'
import { SourceBusterType } from '@infrastructure/SourceBuster'

export const setLocaleCookie = (lang: string): void => {
    Cookies.set(LANGUAGE_COOKIE_NAME, lang, { domain: COOKIE_DOMAIN })
}

export const getLocaleCookie = (): Opt<string> => {
    const value = Cookies.get(LANGUAGE_COOKIE_NAME)

    // in case we have 2 cookies set for www.icontainers.com and .icontainers.com we need to remove the first one
    if (value && value !== Cookies.get()[LANGUAGE_COOKIE_NAME]) {
        Cookies.remove(LANGUAGE_COOKIE_NAME)
    }

    return Cookies.get(LANGUAGE_COOKIE_NAME)
}

export const setGdprCookie = (cookiesState: CookiesState): void => {
    Cookies.set(GDPR_MANDATORY_COOKIE_NAME, `${cookiesState.necessary}`, {
        domain: COOKIE_DOMAIN,
        expires: GDPR_COOKIES_EXPIRES_IN_DAYS,
    })
    Cookies.set(GDPR_PERFORMANCE_COOKIE_NAME, `${cookiesState.performance}`, {
        domain: COOKIE_DOMAIN,
        expires: GDPR_COOKIES_EXPIRES_IN_DAYS,
    })
    Cookies.set(GDPR_MARKETING_COOKIE_NAME, `${cookiesState.marketing}`, {
        domain: COOKIE_DOMAIN,
        expires: GDPR_COOKIES_EXPIRES_IN_DAYS,
    })
}

export const removeOldGdprCookie = (): void =>
    Cookies.remove(OLD_GDPR_COOKIE_NAME, {
        domain: COOKIE_DOMAIN,
        expires: GDPR_COOKIES_EXPIRES_IN_DAYS,
    })

export const getMandatoryGdprCookie = (): string | undefined => {
    //TODO: Remove this condition after release of 180 days
    const oldGDPRCookie = Cookies.get(OLD_GDPR_COOKIE_NAME)
    if (oldGDPRCookie) {
        removeOldGdprCookie()
    }

    return Cookies.get(GDPR_MANDATORY_COOKIE_NAME)
}

export const getPerformanceGdprCookie = (): string | undefined => Cookies.get(GDPR_PERFORMANCE_COOKIE_NAME)

export const getMarketingGdprCookie = (): string | undefined => Cookies.get(GDPR_MARKETING_COOKIE_NAME)

const MAP_GDPR_COOKIE_STATE_WITH_LIBRARY: Record<SourceBusterType | MixpanelType | ClarityType, CookiesType> = {
    SourceBuster: 'performance',
    mixpanel: 'performance',
    Clarity: 'performance',
}

export const isCookieAccepted = (cookie?: CookiesType): boolean => {
    switch (cookie) {
        case 'performance':
            return getPerformanceGdprCookie() === `true`
        case 'marketing':
            return getMarketingGdprCookie() === `true`
        default:
            return getMandatoryGdprCookie() === `true`
    }
}

export const isGdprCookieAccepted = (key: SourceBusterType | MixpanelType | ClarityType): boolean => {
    const library = MAP_GDPR_COOKIE_STATE_WITH_LIBRARY[key]
    return isCookieAccepted(library)
}

export const isMandatoryCookieAccepted = (): boolean => {
    if (!showCookiesPopup()) {
        setGdprCookie({ necessary: true, marketing: true, performance: true })
        return true
    }

    return getMandatoryGdprCookie() === `true`
}

export const getVisitorUuidCookies = (): Opt<string> => Cookies.get(VISITOR_UUID_COOKIE_NAME)

export const setVisitorUuidCookies = (uuid: string): void => {
    Cookies.set(VISITOR_UUID_COOKIE_NAME, uuid, {
        domain: COOKIE_DOMAIN,
        expires: VISITOR_UUID_COOKIES_EXPIRES_IN_DAYS,
    })
}
