module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/icon-sourcebuster/gatsby-browser.ts'),
      options: {"plugins":[],"domain":"boxit4me.com"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":100},
    },{
      plugin: require('../plugins/icon-mixpanel/gatsby-browser.ts'),
      options: {"plugins":[],"key":"06ac2c582a83e86feb66e5dc3b72bce4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WF4B4WV","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"hotjar":{"hjid":"5211395","hjsv":"6","cookieName":"performanceCookieTracker"},"environments":["production","development"]},
    },{
      plugin: require('../plugins/icon-salesforce-live-chat/gatsby-browser.ts'),
      options: {"plugins":[],"domainName":"la1-c1-par","organizationId":"00D0N000001MRzX","deploymentId":"5720N000000g1rz","buttonId":"5730N000000g1my","apiURL":"https://boxit4me-cms.private.icontainers.com","accessToken":"f8724eace4bd9b6f4e81fb962524b513d3d4c4c31724897967a2eb052da6d079cefb1a06a5d910a9ebbf1f3c458f913833e706815dc55d8c66f8ada256f0cdab00b52e0b892fa675599a2e7980fa64281d5900fdb03a0bbe6453cfe3fc58c48f4cfb28a9a684d50dd4fc3cd580ce3d39b4c27d1f04aa5506c4d27afbd89b7d70"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
