/* eslint-disable no-underscore-dangle */
type InitSalesforceLiveAgent = ({
    domainName,
    onLoad,
}: {
    domainName: string

    onLoad: () => void
}) => void

export const initSalesforceLiveAgent: InitSalesforceLiveAgent = (params) => {
    const { domainName, onLoad } = params

    const script = document.createElement('script')
    script.src = `https://c.${domainName}.salesforceliveagent.com/content/g/js/42.0/deployment.js`
    script.async = true
    script.onload = () => {
        onLoad()
    }
    document.body.appendChild(script)
}

type OnLoadSalesforceScript = ({
    domainName,
    deploymentId,
    organizationId,
    buttonId,
    onlineButtonId,
    offlineButtonId,
}: {
    domainName: string
    deploymentId: string
    organizationId: string
    buttonId: string
    onlineButtonId: string
    offlineButtonId: string
}) => () => void

export const onLoadSalesforceScript: OnLoadSalesforceScript = (params) => () => {
    const { domainName, deploymentId, organizationId, buttonId, onlineButtonId, offlineButtonId } = params

    if (window.liveagent) {
        window.liveagent.init(`https://d.${domainName}.salesforceliveagent.com/chat`, deploymentId, organizationId)
        if (!window._laq) {
            window._laq = []
        }
        window._laq.push(() => {
            window.liveagent.showWhenOnline(buttonId, document.getElementById(onlineButtonId))
            window.liveagent.showWhenOffline(buttonId, document.getElementById(offlineButtonId))
        })
    }
}
