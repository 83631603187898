export const handleOnlineClick = (buttonId: string) => () => {
    if (window.liveagent) {
        window.liveagent.startChat(buttonId)
    }
}

export const getButtonInnerText = (key: string, data: undefined | Record<'attributes', Record<string, string>>[]) => {
    let innerText = ''

    data?.forEach((row) => {
        innerText += `<span class="button_inner_text ${row.attributes['locale']}">${row.attributes[key] ?? key}</span> `
    })

    return innerText || key
}

export const addStyle = (data: undefined | Record<'attributes', Record<string, string>>[]) => {
    const style = document.createElement('style')

    let styleRule = '.button_inner_text{display:none;} .icon-wrapper{padding-left: 8px; display:flex;}'
    data?.forEach((row) => {
        styleRule += `[lang=${row.attributes['locale']}] .button_inner_text.${row.attributes['locale']}{ display:block; }`
    })

    style.innerHTML = styleRule

    document.head.appendChild(style)
}

export const createButton = (id: string, innerText: string, icon: string, isDisabled = false) => {
    const button = document.createElement('button')
    button.setAttribute('id', id)
    button.setAttribute('class', 'custom-button salesforce-chat-button')
    if (isDisabled) {
        button.setAttribute('disabled', '')
        button.classList.add('disabled')
    }
    button.innerHTML = `${innerText} <span class="icon-wrapper">${icon}</span>`
    return button
}

export const fetchTranslations = async (apiURL: string, accessToken: string) => {
    const response = await fetch(`${apiURL}/api/translations?locale=all`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
    })
    const data = await response.json()
    return data?.data || {}
}

export const getIcon = (color: string): string => `
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0867 19.8877L11.6288 18.9718C12.0492 18.2614 12.2595 17.9062 12.5972 17.7098C12.9349 17.5134 13.36 17.5061 14.2104 17.4915C15.4658 17.4698 16.2531 17.3929 16.9134 17.1194C18.1386 16.6119 19.1119 15.6386 19.6194 14.4134C20 13.4946 20 12.3297 20 10V9C20 5.72657 20 4.08985 19.2632 2.88751C18.8509 2.21473 18.2853 1.64908 17.6125 1.2368C16.4101 0.5 14.7734 0.5 11.5 0.5H8.5C5.22657 0.5 3.58985 0.5 2.38751 1.2368C1.71473 1.64908 1.14908 2.21473 0.7368 2.88751C-5.96046e-08 4.08985 0 5.72657 0 9V10C0 12.3297 -2.98023e-08 13.4946 0.3806 14.4134C0.88807 15.6386 1.86144 16.6119 3.08658 17.1194C3.74689 17.3929 4.53422 17.4698 5.78958 17.4915C6.63992 17.5061 7.06509 17.5134 7.40279 17.7098C7.74049 17.9063 7.95073 18.2614 8.3712 18.9718L8.9133 19.8877C9.3965 20.704 10.6035 20.704 11.0867 19.8877ZM14 10.5C14.5523 10.5 15 10.0523 15 9.5C15 8.9477 14.5523 8.5 14 8.5C13.4477 8.5 13 8.9477 13 9.5C13 10.0523 13.4477 10.5 14 10.5ZM11 9.5C11 10.0523 10.5523 10.5 10 10.5C9.4477 10.5 9 10.0523 9 9.5C9 8.9477 9.4477 8.5 10 8.5C10.5523 8.5 11 8.9477 11 9.5ZM6 10.5C6.55228 10.5 7 10.0523 7 9.5C7 8.9477 6.55228 8.5 6 8.5C5.44772 8.5 5 8.9477 5 9.5C5 10.0523 5.44772 10.5 6 10.5Z" fill="${color}"></path>
    </svg>
`
