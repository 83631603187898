import { showCookiesPopup } from '@icontainers/design-system/dist/utils'
import { isGdprCookieAccepted } from '@infrastructure/Cookies'

import { initClarity } from './initClarity'

export type ClarityType = 'Clarity'

export const clarityConsent = () => {
    if (typeof window.clarity === 'function') {
        window.clarity('consent')
    }
}

export const initializeClarity = () => {
    const gdprCookie = isGdprCookieAccepted('Clarity')
    const clarityId = process.env.GATSBY_CLARITY_ID

    if ((gdprCookie || !showCookiesPopup()) && clarityId) {
        initClarity(window, document, 'clarity', 'script', clarityId, undefined, undefined, clarityConsent)
    }
}
