/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
export const initClarity = (c, l, a, r, i, t, y, onload) => {
    c[a] =
        c[a] ||
        function () {
            ;(c[a].q = c[a].q || []).push(arguments)
        }
    t = l.createElement(r)
    t.async = 1
    t.src = 'https://www.clarity.ms/tag/' + i
    y = l.getElementsByTagName(r)[0]
    y.parentNode.insertBefore(t, y)
    t.onload = function () {
        onload()
    }
}
